import React, { CSSProperties } from 'react';
import NoData from '../../Common/Wrapper/NoData';

import styles from './index.module.scss';

export interface NoResultProps {
  style?: CSSProperties;
}

export default function NoResult({ style }: NoResultProps): React.ReactNode {
  return (
    <div className={styles.notResult} style={style}>
      <NoData
        type="noResult"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>no results</span>
            <br />
            <b>found</b>
          </>
        )}
        text="Sorry, we couldn&apos;t find any results. Please check your spelling or try different keywords."
      />
    </div>
  );
}
