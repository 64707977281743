import React, { useEffect } from 'react';

import {
  Button, Modal,
} from 'antd';
import { CloseIcon } from '../../../../Icon';
import { CollectionByIdGet, useCollectionUpdate } from '../../../../../../hooks/api/collection';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import { ShareGet } from '../../../../../../hooks/api/share';

import styles from './index.module.scss';

interface DeleteProps {
  id?: string;
  collection?: CollectionByIdGet | ShareGet;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Delete({
  id, collection, isModalOpen, handleOk, handleCancel,
}: DeleteProps): React.ReactNode {
  const collectionItemDelete = useCollectionUpdate(collection?.id);
  const {
    loading, error, response, fetch,
  } = collectionItemDelete;

  useMessageError([collectionItemDelete]);
  useMessageSuccess([collectionItemDelete], 'Asset removed from collection successfully!');

  useEffect(() => {
    if (!loading && !error && response) {
      handleOk();
    }
  }, [response]);

  return (
    <Modal
      width={420}
      title="Remove item from collection"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDelete}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          Do you want remove item the collection?
        </div>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            fetch({
              assets: collection?.assets
                .filter(({ id: assetId }) => assetId !== id)
                .map((({ id: assetId }) => assetId)),
            });
          }}
          loading={loading}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
}
