import React from 'react';

import NoData from '../../Common/Wrapper/NoData';

import styles from './index.module.scss';

export default function NoAssetsInCollection(): React.ReactNode {
  return (
    <div className={styles.notCollection}>
      <NoData
        type="noCollection"
        title={(
          <span style={{ fontWeight: 300 }}>no assets in collections</span>
        )}
      />
    </div>
  );
}
