import React, {
  useEffect, useState,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import {
  Button, Form, Input, Radio, Select,
} from 'antd';
import { NavLink } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import ContentWrapper from '../../Common/Content/Wrapper';
import {
  DefaultFetchError, useFetchCreate, useFetchGet, useFetchUpdate,
} from '../../../hooks/fetch';
import Loading from '../../Common/Loading';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { BigArrowIcon } from '../../Common/Icon';

import styles from './index.module.scss';
import { AnyObject } from '../../../types';
import { useMessageSuccess } from '../../../hooks/common';

const customizeRequiredMark = (label: React.ReactNode) => label;

const listCountry = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Ascension Island',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Canary Islands',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Ceuta, Melilla',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Clipperton Island',
  'Cocos Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Côte d\'Ivoire',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czech Republic',
  'Democratic People\'s Republic of Korea',
  'Democratic Republic of the Congo',
  'Denmark',
  'Diego Garcia',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'France, Metropolitan',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Lao People\'s Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'Netherlands AntillesNew Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of Korea',
  'Réunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tristan da Cunha',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State',
  'Venezuela',
  'Vietnam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wallis and Futuna',
  'Western Sahara',
  'XXSudanXX',
  'Yemen',
  'Yugoslavia',
  'Zaire',
  'Zambia',
  'Zimbabwe',
];

const listCountryForSelect = listCountry.map((value) => ({
  value,
  label: value,
}));

interface FormFields {
  gender: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  company: string;
  company2: string;
}

interface UserInfo {
  id: string; // uuid
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  userProfileMetadata: {
    attributes: [
      {
        name: string;
        displayName: string;
        required: boolean;
        readOnly: boolean;
        validators: AnyObject
      },
      {
        name: string;
          displayName: string;
        required: boolean;
          readOnly: boolean;
        validators: {
          email: {
            'ignore.empty.value': boolean;
          }
        }
      },
      {
        name: string;
        displayName: string;
        required: boolean;
        readOnly: boolean;
        validators: AnyObject;
      },
      {
        name: string;
        displayName: string;
        required: boolean;
        readOnly: boolean;
        validators: AnyObject;
      }
    ]
  },
  attributes: AnyObject;

  gender?: string;
  phone?: string;
  country?: string;
  company?: string;
  company2?: string;
}

interface AccountProps {
  url: string;
  keycloak: Keycloak;
}

function Account({ url, keycloak }: AccountProps): React.ReactNode {
  const userInfo = useFetchGet<UserInfo>('', {
    url: url.replace('protocol/openid-connect/userinfo', 'account'),
    latest: true,
    startStateLoading: true,
  });
  const updateInfo = useFetchCreate<UserInfo, DefaultFetchError, UserInfo>('', {
    url: url.replace('protocol/openid-connect/userinfo', 'account'),
  });
  const [form] = Form.useForm<FormFields>();

  useMessageSuccess([updateInfo], ' Your adjustments have been successfully saved!');

  useEffect(() => {
    userInfo.fetch();
  }, []);

  useEffect(() => {
    if (userInfo.data) {
      form.setFieldsValue({
        gender: userInfo.data?.gender,
        firstName: userInfo.data.firstName,
        lastName: userInfo.data.lastName,
        email: userInfo.data.email,
        phone: userInfo.data?.phone,
        country: userInfo.data?.country,
        company: userInfo.data?.company,
        company2: userInfo.data?.company2,
      });
    }
  }, [userInfo.data]);

  const onFinish = () => {
    if (userInfo.data) {
      const {
        firstName, lastName, email, // gender, country, company,
      } = form.getFieldsValue();

      updateInfo.fetch({
        ...userInfo.data,
        firstName,
        lastName,
        email,
        // gender,
        // country,
        // company,
        // gbtcompany: ['']
      });
    }
  };

  const onFinishFailed = () => {
    console.log(form.getFieldsError(), form.getFieldsValue());
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          Personal data
        </div>
      </div>

      <Form<FormFields>
        className={styles.account}
        form={form}
        name="basic"
        layout="vertical"
        // initialValues={{ gen}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={customizeRequiredMark}
      >
        <div className={styles.label}>
          Gender*
        </div>
        <Form.Item name="gender" label={' '} className={styles.color} rules={[{ required: true }]}>
          <Radio.Group size="large">
            <Radio value="female">Female</Radio>
            <Radio value="male">Male</Radio>
            <Radio value="other">Other</Radio>
            <Radio value="nothing">Prefer not to say</Radio>
          </Radio.Group>
        </Form.Item>

        <div className={styles.line} />

        <div className={styles.label}>
          Personal information
        </div>
        <div className={styles.row}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: false }]}
          >
            <Input size="large" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: false }]}
          >
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
        </div>

        <div className={styles.line} />

        <div className={styles.label}>
          Contact information
        </div>
        <div className={styles.row}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[{ required: false }]}
          >
            <Input size="large" placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: false }]}
          >
            <Input size="large" placeholder="Phone" />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: false }]}
          >
            <Select size="large" options={listCountryForSelect} placeholder="Please select" />
          </Form.Item>
        </div>

        <div className={styles.line} />

        <div className={styles.label}>
          Corporative information*
        </div>
        <div className={styles.row}>
          <Form.Item
            name="company"
            label="Which Company do you work for?*"
            rules={[{ required: true, message: 'Please enter which company do you work for' }]}
          >
            <Input size="large" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="company2"
            label=" "
            rules={[{ required: false }]}
          >
            <Input size="large" placeholder="Please enter" />
          </Form.Item>
        </div>

        <Button type="primary" htmlType="submit" size="large" loading={updateInfo.loading}>
          Update personal Data
        </Button>
      </Form>

      <NavLink to="/" className={styles.changePassword}>
        <BigArrowIcon />
        Change password
      </NavLink>

      {userInfo.loading ? (
        <Loading />
      ) : null}
    </div>
  );
}

export default function Wrapper() {
  const { keycloak, initialized } = useKeycloak();
  const [url, setUrl] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (keycloak && keycloak?.endpoints && initialized) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setUrl(keycloak?.endpoints?.userinfo());
    }
  }, [keycloak, initialized]);

  return (
    <ContentWrapper>
      <Breadcrumbs
        loading={false}
        list={[
          {
            name: 'Personal Data',
          },
        ]}
      />

      {url ? <Account url={url} keycloak={keycloak} /> : null}
    </ContentWrapper>
  );
}
