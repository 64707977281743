import React, { CSSProperties, useMemo } from 'react';
import {
  createSearchParams, NavLink,
} from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Route } from '../../../routes';
import { User } from '../../../types/user';
import { AnyObject } from '../../../types';
import { CollectionIcon } from '../../Common/Icon';
import Collections from '../../../pages/Collections';
import Collection from '../../../pages/Collections/Collection';
import UserMenu from './User';
import Search from './Search';
import Lang from './Lang';

import styles from './index.module.scss';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;
  Component?: (props: any) => React.ReactNode;
  children?: MenuRoute[];
  style?: CSSProperties;
}

export const routes: MenuRoute[] = [
  {
    Component: Search,
    name: 'Search',
    style: { width: '100%' },
  },
  {
    style: {
      width: 78,
      justifyContent: 'flex-start',
    },
    Component: Lang,
    name: 'Lang',
  },
  {
    icon: <CollectionIcon />,
    name: 'Collections',
    route: {
      path: 'collection',
      element: <Collections />,
    },
    children: [
      {
        icon: <CollectionIcon />,
        name: 'Collection',
        route: {
          path: ':id',
          element: <Collection />,
        },
      },
    ],
  },
  {
    Component: UserMenu,
    name: 'User',
  },
  // {
  //   privateRoute: true,
  //   icon: <UserOutlined style={{ fontSize: 18 }} />,
  //   className: 'icon-padding-mini-sub',
  //   children: [
  //     {
  //       privateRoute: true,
  //       icon: <LogoutOutlined />,
  //       name: 'Sign out',
  //       onClick: () => {
  //         store.dispatch(signOut());
  //       },
  //     },
  //   ],
  // },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

export default function Menu(): React.ReactNode {
  const { keycloak } = useKeycloak();
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const user = keycloak?.userInfo as User;
  const menuList = useMemo(() => routes
    .filter(({ ignoreMenu }) => ignoreMenu !== true)
    .filter(({ name }) => !(name === 'Lang' && isMobile))
    .map(({
      route, name, icon, key, onClick, simple, Component, ...data
    }) => ({
      // eslint-disable-next-line no-nested-ternary
      link: route?.path ? (
        <NavLink
          key={route.path}
          to={`/${route.path}${paramsToString(user, route.params)}`}
          title={typeof name === 'function' ? name(user) : name}
        >
          {typeof icon === 'function' ? icon(user) : icon}
        </NavLink>
      )
      // eslint-disable-next-line no-nested-ternary,unicorn/no-nested-ternary
        : (Component ? <Component /> : (
          <a
            role="search"
            onClick={(e) => (onClick ? onClick(e)
              : e.preventDefault())}
          >
            {(typeof icon === 'function' ? icon(user) : icon)}
          </a>
        )),
      key: key || route?.path || name,
      ...data,
    })), [user, isMobile]);

  const lang = menuList.find(({ key }) => key === 'Lang');

  return (
    <div className={styles.menu}>
      {keycloak.authenticated ? (
        menuList.map(({ link, style, key }) => (
          <div
            style={style}
            key={key}
            className={clsx(styles.item)}
          >
            {link}
          </div>
        ))
      ) : (
        <div
          style={lang?.style}
          key={lang?.key}
          className={clsx(styles.item)}
        >
          {lang?.link}
        </div>
      )}
    </div>
  );
}
