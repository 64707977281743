import { useEffect, useMemo, useState } from 'react';
import { App, theme } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  FetchCreate, FetchDelete, FetchGet, FetchGetId, FetchUpdate, getMessageInError, PagingDataResponse,
} from './fetch';
import { useSimpleModal } from '../components/Common/Modal/Simple';
import { AnyObject } from '../types';

export interface Item extends DefaultOptionType {
  key?: string | string[];
  color?: string;
}

export function sortByDisable(list: Item[]): Item[] {
  return [
    ...list.filter(({ disabled }) => !disabled),
    ...list.filter(({ disabled }) => disabled),
  ];
}

export type SelectData = string[] | AnyObject[] | PagingDataResponse<string[] | AnyObject[]>

export function selectData<D = SelectData>(
  resource: D,
  decorateData?: (data: AnyObject, id: number, item: Item) => Item,
  defaultList?: Item[],
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = Array.isArray(resource) ? resource : resource?.data;

  if (data) {
    return data.map((value: string | AnyObject, id: number) => {
      if (typeof value === 'string') {
        return {
          label: value,
          key: value,
          value,
        };
      }

      const key = value?.ids || value?.id || value?.key || value?.value;
      const result: Item = {
        label: value?.name || value?.title || value?.description || value?.value,
        value: key,
        key,
      };

      if (decorateData) {
        return decorateData(data, id, result);
      }

      return result;
    }) as Item[];
  }

  return defaultList || [];
}

export function useSelectData<D = SelectData>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resource?: FetchGet<D, any, any, any>,
  decorateData?: (data: AnyObject, id: number, item: Item) => Item,
  defaultList?: Item[],
): Item[] {
  return useMemo(() => {
    if (resource && !resource.error && resource.data) {
      return selectData(resource.data, decorateData, defaultList);
    }

    return defaultList || [];
  }, [resource?.data, defaultList]);
}

export function useMessageError(
  listFetch: (FetchCreate | FetchGet | FetchUpdate | FetchDelete)[],
) {
  const { message } = App.useApp();

  useEffect(() => {
    for (const fetch of listFetch) {
      if (fetch.error) {
        message.error(
          <span
            dangerouslySetInnerHTML={{
              __html: fetch.name ? `${fetch.name}: ${getMessageInError(fetch.error)}` : getMessageInError(fetch.error),
            }}
          />,
        );
        fetch.clearError();
      }
    }
  }, [...listFetch.map((fetch) => fetch.error)]);
}

export function useMessageModalError(
  listFetch: [FetchCreate | FetchGet | FetchGetId | FetchUpdate | FetchDelete, string][],
): React.ReactElement {
  const { open, contextHolder } = useSimpleModal({ type: 'error' });

  useEffect(() => {
    for (const [fetch, title] of listFetch) {
      if (fetch?.error) {
        open({
          icon: <CloseCircleFilled />,
          title,
          content: (
            <span>
              {fetch.name ? `${fetch.name}: ${getMessageInError(fetch.error)}` : getMessageInError(fetch.error)}
            </span>
          ),
          footer: false,
          onCancel: () => fetch.clearError(),
        });
      }
    }
  }, [...listFetch.map(([fetch]) => fetch?.error)]);

  return contextHolder;
}

export function useMessageSuccess(
  listFetch: (FetchCreate | FetchGet | FetchUpdate | FetchDelete)[],
  text: string,
) {
  const { message } = App.useApp();

  useEffect(() => {
    for (const fetch of listFetch) {
      const status = fetch.response?.status || 0;

      if (!fetch.error && !fetch.loading && status >= 200 && status <= 300) {
        message.success(text);
        fetch.clearResponse();
      }
    }
  }, [...listFetch.map((fetch) => fetch.data)]);
}

export function useLoadingTimeout(resources: FetchGet[], time = 500, defaultState = true) {
  const [loading, setLoading] = useState(defaultState);

  useEffect(() => {
    let id: NodeJS.Timeout | undefined;

    if (resources.some((resource) => resource.loading)) {
      setLoading(true);
    } else {
      id = setTimeout(() => {
        setLoading(false);
      }, time);
    }

    return () => clearTimeout(id);
  }, resources.map((resource) => resource.loading));

  return loading;
}

export default {
  useSelectData,
  useLoadingTimeout,
  useMessageError,
};
