import React, { useEffect, useMemo, useState } from 'react';
import clsx, { ClassValue } from 'clsx';

import { App, Button, Spin } from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { usePrepareFile } from '../../../context/prepareFile';
import { ArrowTopIcon, CloseIcon } from '../Icon';

import styles from './index.module.scss';

export interface ContentWrapperProps {
  className?: string | ClassValue;
}

let listOfDisplayedSuccessMessages: number[] = [];

export default function PrepareFile({
  className = '',
}: ContentWrapperProps): React.ReactNode {
  const { list, remove } = usePrepareFile();
  const [active, setActive] = useState(false);
  const { message } = App.useApp();
  const openList = useMemo(() => list.filter(({ close }) => !close), [list]);

  useEffect(() => {
    if (list.length === 0) {
      listOfDisplayedSuccessMessages = [];
    }
  }, [list]);

  useEffect(() => {
    openList.forEach(({
      name, success, assets, id,
    }) => {
      if (success && !listOfDisplayedSuccessMessages.includes(id) && assets && assets.length > 0) {
        listOfDisplayedSuccessMessages.push(id);
        message.success(`The file${assets.length === 1 ? '' : 's'} are ready for download: ${name}`);
      }
    });
  }, [openList]);

  if (openList.length === 0) {
    return null;
  }

  if (!active) {
    return (
      <div className={styles.prepareFileArrow}>
        <div
          role="none"
          className={styles.open}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActive(true);
          }}
        >
          <ArrowTopIcon />
        </div>
        <div>
          {openList.some(({ loading }) => loading) ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <CheckOutlined className={styles.done} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.prepareFile, className)}>
      <div className={styles.head}>
        File upload queue

        <div
          role="none"
          className={styles.close}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActive(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className={styles.content}>
        {openList.map(({
          id, queryParams, name, responses, success, assets,
        }) => (
          <div key={`${queryParams}-${name}`} className={styles.wrp}>
            <div className={styles.name}>
              {name}
            </div>
            {success ? (
              <div className={styles.success}>
                <CheckOutlined className={styles.done} />
              </div>
            ) : (
              <div className={styles.counter}>
                <Spin indicator={<LoadingOutlined spin />} />
                <div className={styles.current}>
                  {responses.length}
                </div>
                <div className={styles.split}>
                  /
                </div>
                <div className={styles.total}>
                  {assets?.length}
                </div>
              </div>
            )}
            <div className={styles.actions}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  remove(id);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!success}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.assign(`${process.env.REACT_APP_API}file?${queryParams}`);
                  remove(id);
                }}
              >
                Download
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
