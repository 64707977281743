import React, { useEffect } from 'react';

import {
  Button, Form, Input, Modal,
} from 'antd';
import { useParams } from 'react-router-dom';
import { CloseIcon } from '../../../Icon';
import {
  CollectionByIdGet,
  CollectionCreateForm,
  useCollectionUpdate,
} from '../../../../../hooks/api/collection';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';

import styles from './index.module.scss';

interface CreateProps {
  collection?: CollectionByIdGet;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Edit({
  collection, isModalOpen, handleOk, handleCancel,
}: CreateProps): React.ReactNode {
  const [form] = Form.useForm();
  const collectionUpdate = useCollectionUpdate();
  const { id } = useParams();
  const {
    loading, error, response, fetch,
  } = collectionUpdate;

  useMessageError([collectionUpdate]);
  useMessageSuccess([collectionUpdate], 'Collection update successfully!');

  useEffect(() => {
    if (!loading && !error && response) {
      form.resetFields();
      handleOk();
    }
  }, [response]);

  const handleSubmit = (formItems: CollectionCreateForm) => {
    fetch(formItems, id);
  };

  return (
    <Modal
      width={420}
      title="Edit collection"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalCreate}
    >
      <div className={styles.content}>
        <Form<CollectionCreateForm>
          initialValues={collection}
          requiredMark={false}
          name="trigger"
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Name"
            name="name"
            validateDebounce={500}
            rules={[{ min: 2, required: true }]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Description (optional)"
            name="description"
            validateDebounce={500}
          >
            <Input.TextArea
              placeholder="Description"
              size="large"
              rows={1}
            />
          </Form.Item>
        </Form>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            form.submit();
          }}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
