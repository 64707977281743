import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { Image } from 'antd';
import { ZoomIcon } from '../../../Common/Icon';
import { AssetByIdGet } from '../../../../hooks/api/asset';

import styles from './index.module.scss';

export interface GalleryProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Gallery({
  data, className = '',
}: GalleryProps): React.ReactNode {
  return (
    <div className={clsx(styles.gallery, className)}>
      {data?.mimetype.includes('video') ? (
        <Image
          key={data?.urlExport || data?.urlMaster}
          src={data?.urlPreview || data?.urlExport}
          onError={(e: any) => {
            e.target.src = '/resource/images/asset.png';
          }}
          preview={{
            destroyOnClose: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            imageRender: () => (
              <video
                autoPlay
                controls
                src={data?.urlExport}
                className={styles.video}
                preload="auto"
                x-webkit-airplay="allow"
              >
                <track default kind="captions" />
              </video>
            ),
            toolbarRender: () => null,
          }}
        />
      ) : (
        <Image
          key={data?.urlExport || data?.urlMaster}
          src={data?.urlPreview || data?.urlExport}
          onError={(e: any) => {
            e.target.src = '/resource/images/asset.png';
          }}
          placeholder={(
            <Image
              preview={false}
              src={data?.urlExport || data?.urlPreview}
              onError={(e: any) => {
                e.target.src = '/resource/images/asset.png';
              }}
            />
          )}
          preview={{
            toolbarRender: () => null,
            imageRender: (data?.format === 'pdf' ? (
              // eslint-disable-next-line react/no-unstable-nested-components
              () => (
                <iframe
                  className={styles.iframe}
                  key={data?.urlExport || data?.urlMaster}
                  title={data?.name}
                  src={data?.urlExport}
                />
              )
            ) : (node: any) => React.cloneElement(node, {
              src: data?.urlExport || data?.urlPreview,
              onError: (e: any) => {
                e.target.src = '/resource/images/asset.png';
              },
            })),
          }}
        />
      )}

      <ZoomIcon className={styles.zoom} />
    </div>
  );
}
