import React, { useEffect } from 'react';

import { Button, Modal } from 'antd';
import { CloseIcon } from '../../../Icon';
import Point from '../../../Point';
import { AssetByIdGet } from '../../../../../hooks/api/asset';
import { ButtonAccept } from '../../Collections/Download';

import styles from './index.module.scss';
import { RestrictionsStatusAlias } from '../../../../Pages/Asset/Info';

interface DownloadProps {
  autoStart?: boolean;
  urlExport?: string;
  asset?: AssetByIdGet;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Download({
  autoStart = false, urlExport, asset, isModalOpen, handleOk, handleCancel,
}: DownloadProps): React.ReactNode {
  useEffect(() => {
    if (autoStart && isModalOpen) {
      setTimeout(handleOk, 50);
    }
  }, [autoStart]);

  if (urlExport && asset && autoStart && isModalOpen) {
    return (
      <ButtonAccept
        autoStart={autoStart && isModalOpen}
        name={asset.name}
        assets={[asset]}
        handleCancel={handleCancel}
        text="Preparation of files"
        url={`${process.env.REACT_APP_API}file/${asset?.id}`}
      />
    );
  }

  return (
    <Modal
      width={420}
      title="Usage restrictions information"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDownload}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          {asset?.allData?.name}
        </div>

        {asset?.restriction ? (
          <div className={styles.list}>
            <Point status={asset?.restriction} text={RestrictionsStatusAlias[asset?.restriction]} />
          </div>
        ) : null}
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        {urlExport && asset ? (
          <ButtonAccept
            autoStart={autoStart && isModalOpen}
            name={asset.name}
            assets={[asset]}
            handleCancel={handleCancel}
            text="Preparation of files"
            url={`${process.env.REACT_APP_API}file/${asset?.id}`}
          />
        ) : null}
      </div>
    </Modal>
  );
}
