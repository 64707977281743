import React from 'react';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import './index.scss';

import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';
import LangProvider from './context/lang';
import PrepareFileProvider from './context/prepareFile';

dayjs.extend(localeData);

export default function App(): React.ReactNode {
  return (
    <ReactKeycloakProvider
      authClient={new Keycloak({
        realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
        url: process.env.REACT_APP_KEYCLOAK_URL || '',
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
      })}
    >
      <AntdConfigProvider>
        <LangProvider>
          <PrepareFileProvider>
            <Routes />
          </PrepareFileProvider>
        </LangProvider>
      </AntdConfigProvider>
    </ReactKeycloakProvider>
  );
}
