import React from 'react';

import { Button } from 'antd';
import NoData from '../../Common/Wrapper/NoData';
import { PlusIcon } from '../../Common/Icon';

import styles from './index.module.scss';

export interface NoCollectionProps {
  showModalCreate?: () => void;
}

export default function NoCollection({ showModalCreate }: NoCollectionProps): React.ReactNode {
  return (
    <div className={styles.notCollection}>
      <NoData
        type="noCollection"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>no collections</span>
            <br />
            <b>Created</b>
          </>
        )}
        text="Start your collection right now."
        action={(
          <Button
            icon={<PlusIcon />}
            size="large"
            style={{ marginTop: 24 }}
            onClick={showModalCreate ? (e) => {
              e.preventDefault();
              showModalCreate();
            } : undefined}
          >
            Create New Collection
          </Button>
        )}
      />
    </div>
  );
}
