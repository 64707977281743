import React, { useEffect } from 'react';

import {
  Button, Modal,
} from 'antd';
import { CloseIcon } from '../../../Icon';
import { useCollectionDelete } from '../../../../../hooks/api/collection';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import { useSearchParams } from '../../../../../hooks/useSearchParams';

import styles from './index.module.scss';

interface DeleteProps {
  id?: string;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Delete({
  id, isModalOpen, handleOk, handleCancel,
}: DeleteProps): React.ReactNode {
  const [_, setSearchParams, searchParams] = useSearchParams({}, false);
  const collectionDelete = useCollectionDelete(id);
  const {
    loading, error, response, fetch,
  } = collectionDelete;

  useMessageError([collectionDelete]);
  useMessageSuccess([collectionDelete], 'Collection delete successfully!');

  useEffect(() => {
    if (!loading && !error && response) {
      handleOk();
      setSearchParams({ ...searchParams, page: '1' });
    }
  }, [response]);

  return (
    <Modal
      width={420}
      title="Delete Collection"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDelete}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          Are you sure you want to delete this collection?
        </div>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            fetch();
          }}
          loading={loading}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
}
