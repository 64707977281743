import React, { useEffect, useState } from 'react';

import {
  Button, Modal,
} from 'antd';
import dayjs from 'dayjs';
import { CheckIcon, CloseIcon } from '../../../Icon';
import { useMessageError } from '../../../../../hooks/common';
import { useLang } from '../../../../../context/lang';
import { useShareCreate } from '../../../../../hooks/api/share';
import { AssetByIdGet } from '../../../../../hooks/api/asset';

import styles from './index.module.scss';

interface ShareProps {
  id: string;
  assets: AssetByIdGet[];
  description?: string;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Share({
  id, assets, description, isModalOpen, handleOk, handleCancel,
}: ShareProps): React.ReactNode {
  const {
    lang: { value: lang },
  } = useLang();
  const shareCreate = useShareCreate();
  const [visibleNewLink, setVisibleNewLink] = useState(false);
  const {
    loading, error, data, fetch,
  } = shareCreate;

  useMessageError([shareCreate]);

  useEffect(() => {
    if (!error && data) {
      setVisibleNewLink(true);
    }
  }, [data]);

  useEffect(() => {
    if (isModalOpen) {
      fetch({
        collectionId: id,
        assets: assets.map((asset) => asset.id),
      });
    }
  }, [isModalOpen]);

  return (
    <Modal
      width={420}
      title="Share"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalShare}
    >
      <div className={styles.content}>
        <div className={styles.info}>
          {visibleNewLink ? (
            <div className={styles.success}>
              <div className={styles.icon}>
                <CheckIcon />
              </div>
              ShareLink Created
            </div>
          ) : null}

          <div className={styles.linkWrp}>
            <div>
              Your Shared Link:
            </div>
            <a href={`${window.location.origin}/${lang}/downloads/${data?.id}`} target="_blank" rel="noreferrer">
              {`${window.location.origin}/${lang}/downloads/${data?.id}`}
            </a>
          </div>

          <div className={styles.expires}>
            Expires in
            {' '}
            {dayjs(data?.expiration).diff(dayjs(), 'd')}
            {' '}
            days (
            {dayjs(data?.expiration).format('DD.MM.YYYY')}
            )
          </div>
        </div>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            const recipient = '';
            const subject = 'Share Media';
            const wrp = document.createElement('div');

            wrp.innerHTML = 'Hello, \n\n'
              + `Your shared link: ${`${window.location.origin}/${lang}/downloads/${data?.id}`}\n\n`
              + `${description ? `Description: ${
                description.slice(0, 3500)
                + (description.length > 3500 ? '...' : '')
              }\n\n` : ''}`
              + `Expire Date: ${dayjs(data?.expiration).format('DD.MM.YYYY')}\n\n`
              + 'Best regards\n'
              + 'Irene Lykhozhon';

            window.open(`mailto:${recipient}?`
              + `subject=${encodeURIComponent(subject)}&`
              + `body=${encodeURIComponent(wrp.innerHTML)}`);
          }}
        >
          Send Via Email
        </Button>
      </div>
    </Modal>
  );
}
