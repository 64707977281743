import React from 'react';
import { Checkbox } from 'antd';
import Item from '../Item';
import Delete from '../Modal/Delete';
import { CollectionByIdGet } from '../../../../../hooks/api/collection';
import { ShareGet } from '../../../../../hooks/api/share';
import { listSize, ListSize } from '../../../Share/SelectedList';

import styles from './index.module.scss';

export interface CollectionContentProps {
  collection?: CollectionByIdGet | ShareGet;
  actions?: { [key: string]: () => void };
  listChecked: [string, ListSize][];
  setListChecked: (list: [string, ListSize][]) => void;
  nameStretch?: boolean;
}

export default function CollectionContent({
  collection, actions, listChecked, setListChecked, nameStretch = false,
}: CollectionContentProps): React.ReactNode {
  return (
    <>
      {collection && collection?.assets.length ? (
        <div className={styles.checkboxCounter}>
          <Checkbox
            checked={collection?.assets.length === listChecked.length}
            onClick={() => {
              if (collection?.assets.length === listChecked.length) {
                setListChecked([]);
              } else {
                setListChecked(collection?.assets?.map(({ id: uuid }) => [uuid, listSize[1]]) || []);
              }
            }}
          />
          {listChecked.length}
          {' '}
          {listChecked.length > 1 ? 'files' : 'file'}
          {' '}
          selected
        </div>
      ) : null}

      <div className={styles.list}>
        {collection?.assets.map(({
          id: uuid, assetId, name, description, urlPreview, urlExport,
        }) => (
          <div
            key={uuid}
            className={styles.wrp}
          >
            <div className={styles.selector}>
              <Checkbox
                checked={listChecked.some(([id]) => id === uuid)}
                onClick={() => {
                  if (listChecked.some(([id]) => id === uuid)) {
                    setListChecked(listChecked.filter(([id]) => id !== uuid));
                  } else {
                    setListChecked([[uuid, listSize[1]], ...listChecked]);
                  }
                }}
              />
            </div>

            <Item
              nameStretch={nameStretch}
              id={uuid}
              to={`/asset/${assetId}`}
              url={urlPreview || urlExport}
              name={name}
              description={description}
              gridSize="minmax(88px, 250px) minmax(58%, 1fr)"
              className={styles.item}
              actions={(
                  actions?.delete ? (
                    <Delete
                      id={uuid}
                      collection={collection}
                      onSuccess={actions.delete}
                    />
                  ) : undefined
                )}
            />
          </div>
        ))}
      </div>
    </>
  );
}
