import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';
import { MenuRoute } from '../../Layout/Menu';
import Imprint from '../../../pages/Imprint';

export interface ContentWrapperProps {
  className?: string | ClassValue;
}

export const routes: MenuRoute[] = [
  {
    privateRoute: true,
    name: 'Imprint',
    route: {
      path: '/imprint',
      element: <Imprint />,
    },
  },
];

export default function Footer({ className = '' }: ContentWrapperProps): React.ReactNode {
  return (
    <div className={clsx(styles.footer, className)}>
      <span className={styles.goto}>
        <b>Go to</b>
        {' '}
        <a
          href="https://www.geberit.com"
          target="_blank"
          title="www.geberit.com"
          rel="noreferrer"
          style={{ marginLeft: 8 }}
        >
          www.geberit.com
        </a>
      </span>

      <div className={clsx(styles.menu, styles.hideMobile)}>
        <span>© 2024 Geberit Sales Ltd</span>
        <NavLink to="/imprint">
          <b>Imprint</b>
        </NavLink>
        <NavLink to="/disclaimer">
          <b>Disclaimer</b>
        </NavLink>
        <NavLink to="/data-privacy">
          <b>Data Privacy</b>
        </NavLink>
        <NavLink to="/eu-declarations">
          <b>EU declarations</b>
        </NavLink>
      </div>

      <div className={clsx(styles.menu, styles.showMobile)}>
        <div>
          <NavLink to="/imprint">
            <b>Imprint</b>
          </NavLink>
        </div>
        <div>
          <NavLink to="/disclaimer">
            <b>Disclaimer</b>
          </NavLink>
        </div>
        <div>
          <NavLink to="/data-privacy">
            <b>Data Privacy</b>
          </NavLink>
        </div>
        <div>
          <NavLink to="/eu-declarations">
            <b>EU declarations</b>
          </NavLink>
        </div>
        <div>
          <span>© 2024 Geberit Sales Ltd</span>
        </div>
      </div>
    </div>
  );
}
