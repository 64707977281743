import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { AssetByIdGet, getFileSize } from '../../../../../hooks/api/asset';
import { humanFileSize } from '../../../../../utils';
import List from '../List';

import styles from '../List/index.module.scss';

const a = [
  {
    key: 'geberit:approval-date',
    name: 'Approval Date',
    type: '5',
    value: '2024-05-28T14:58:21.778Z',
    nameDe: 'Approval Date',
    nameEn: 'Approval Date',
    values: [],
  },
  {
    key: 'geberit:approval-date',
    name: 'Approval Date',
    type: '5',
    value: '2024-05-29T09:29:40.429Z',
    nameDe: 'Approval Date',
    nameEn: 'Approval Date',
    values: [],
  },
  {
    key: 'geberit:asset.category',
    name: '(Geberit) Category',
    type: '29',
    value: 'geberit:category.product-related',
    nameDe: '(Geberit) Kategorie',
    nameEn: '(Geberit) Category',
    values: [
      {
        name: 'Product-related',
        nameDe: 'Product-related',
        nameEn: 'Product-related',
      },
    ],
  },
  {
    key: 'geberit:asset.subcategory',
    name: 'Subcategory',
    type: '29',
    value: 'geberit:category.product-related.insitu',
    nameDe: 'Subkategorie',
    nameEn: 'Subcategory',
    values: [
      {
        name: 'In situ',
        nameDe: 'In situ',
        nameEn: 'In situ',
      },
    ],
  },
  {
    key: 'geberit:brand',
    name: 'Brand',
    type: '2',
    value: 'neutral',
    nameDe: 'Brand',
    nameEn: 'Brand',
    values: [
      {
        name: 'Neutral',
        nameDe: 'Neutral',
        nameEn: 'Neutral',
      },
    ],
  },
  {
    key: 'geberit:dam.features-migrated',
    name: 'DAM features migrated',
    type: '6',
    value: '1',
    nameDe: 'DAM features migrated',
    nameEn: 'DAM features migrated',
    values: [],
  },
  {
    key: 'geberit:dam.media-quality',
    name: 'Media quality',
    type: '2',
    value: 'green',
    nameDe: 'Medienqualität',
    nameEn: 'Media quality',
    values: [
      {
        name: 'Good',
        nameDe: 'Gut',
        nameEn: 'Good',
      },
    ],
  },
  {
    key: 'geberit:dam.producer',
    name: 'Producer',
    type: '29',
    value: 'geberit:tag.producer.hanse_knoedler_fotodesign_radolfzell.7175a5f49c3e5560f71e481fb27087fc',
    nameDe: 'Producer',
    nameEn: 'Producer',
    values: [
      {
        name: 'Hanse Knödler Fotodesign, Radolfzell',
        nameDe: 'Hanse Knödler Fotodesign, Radolfzell',
        nameEn: 'Hanse Knödler Fotodesign, Radolfzell',
      },
    ],
  },
  {
    key: 'geberit:dam.public-url',
    name: 'Public URL',
    type: '4',
    value: 'https://images.data.geberit.com/image/upload/mam/31/26/76/8/cs.3126768.10.jpg',
    nameDe: 'Public URL',
    nameEn: 'Public URL',
    values: [],
  },
  {
    key: 'geberit:dam.usage-rights-migrated',
    name: 'Usage rights migrated',
    type: '6',
    value: '1',
    nameDe: 'Usage rights migrated',
    nameEn: 'Usage rights migrated',
    values: [],
  },
  {
    key: 'geberit:description',
    name: 'Description',
    type: '4',
    value: 'iCon Bathroom with WC wall-hung white matt, Bidet wall-hung white matt, Sigma70 black matt, bidet tap Dornbracht Meta blackmatt,  Floor in Marazzi Powder col Smoke 60x60, back wall HD Surface Argille in light grey',
    nameDe: 'Description',
    nameEn: 'Description',
    values: [],
  },
  {
    key: 'geberit:import.media.data-source',
    name: 'Data Source',
    type: '2',
    value: 'dam-duplicate',
    nameDe: 'Data Source',
    nameEn: 'Data Source',
    values: [
      {
        name: 'Duplicate DAM',
        nameDe: 'Duplicate DAM',
        nameEn: 'Duplicate DAM',
      },
    ],
  },
  {
    key: 'geberit:media-availability',
    name: 'Availability',
    type: '2',
    value: 'internalExternal',
    nameDe: 'Availability',
    nameEn: 'Availability',
    values: [
      {
        name: 'Internal and External',
        nameDe: 'Internal and External',
        nameEn: 'Internal and External',
      },
    ],
  },
  {
    key: 'geberit:owner',
    name: 'Geberit Owner',
    type: '3',
    unit: '',
    value: '3417',
    nameDe: 'Geberit Owner',
    nameEn: 'Geberit Owner',
    values: [],
  },
  {
    key: 'geberit:producer',
    name: 'Producer',
    type: '2',
    value: 'hanse_knoedler_fotodesign_Radolfzell',
    nameDe: 'Producer',
    nameEn: 'Producer',
    values: [
      {
        name: 'Hanse Knödler Fotodesign, Radolfzell',
        nameDe: 'Hanse Knödler Fotodesign, Radolfzell',
        nameEn: 'Hanse Knödler Fotodesign, Radolfzell',
      },
    ],
  },
  {
    key: 'geberit:storage_item.original_info',
    name: 'Geberit Storage Item Original Info',
    type: '0',
    nameDe: 'Geberit Storage Item Original Info',
    nameEn: 'Geberit Storage Item Original Info',
    values: [],
  },
  {
    key: 'geberit:tag.campaign',
    name: 'Campaign',
    type: '29',
    value: 'geberit:tag.campaign.online-catalogue.ewvchhdz9xx698d',
    nameDe: 'Campaign',
    nameEn: 'Campaign',
    values: [
      {
        name: 'Online Catalogue',
        nameDe: 'Online Catalogue',
        nameEn: 'Online Catalogue',
      },
    ],
  },
  {
    key: 'geberit:tag.product-placement',
    name: 'Product placement',
    type: '2',
    value: 'in_situ',
    nameDe: 'Product placement',
    nameEn: 'Product placement',
    values: [
      {
        name: 'In situ',
        nameDe: 'In situ',
        nameEn: 'In situ',
      },
    ],
  },
  {
    key: 'geberit:tag.type',
    name: 'Type',
    type: '29',
    value: 'geberit:tag.type.photo.X53rrT4NcY',
    nameDe: 'Type',
    nameEn: 'Type',
    values: [],
  },
  {
    key: 'geberit:with-person',
    name: 'With person',
    type: '2',
    value: 'no',
    nameDe: 'Mit Person',
    nameEn: 'With person',
    values: [
      {
        name: 'No',
        nameDe: 'Nein',
        nameEn: 'No',
      },
    ],
  },
  {
    key: 'geberit:step.product-item-ref',
    value: '501.663.JT.1 - wWCwd w/WC-st Ge+iCn T53 Rfree set wmatt',
  },
  {
    key: 'geberit:step.product-item-ref',
    value: '115.622.14.1 - act pl Sigma70 dual st st bl matt paint',
  },
  {
    key: 'geberit:step.product-item-ref',
    value: '501.898.JT.1',
  },
  {
    key: 'geberit:step.product-ref',
    value: 'Geberit iCon set of wall-hung WC, washdown, shrouded, Rimfree, with WC seat',
  },
  {
    key: 'geberit:step.product-ref',
    value: 'Geberit iCon wall-hung bidet, shrouded',
  },
  {
    key: 'geberit:step.product-ref',
    value: 'Geberit Sigma70 actuator plate for dual flush',
  },
  {
    key: 'geberit:step.series-ref',
  },
  {
    key: 'censhare:asset.description',
    value: 'iCon Bathroom with WC wall-hung white matt, Bidet wall-hung white matt, Sigma70 black matt, bidet tap Dornbracht Meta blackmatt,  Floor in Marazzi Powder col Smoke 60x60, back wall HD Surface Argille in light grey',
    values: [],
  },
  {
    key: 'censhare:asset.annotation',
    value: '&#xa;---&#xa;2024.05.28, 16:56: Duplicate created of #2905078 | 2023_iCon WC wall-hung white matt with Bidet wall-hung white matt_3',
    values: [],
  },
];

const excludeList = new Set([
  'geberit:description',
  'geberit:dam.public-url',
  'censhare:asset.description',
  'censhare:asset.annotation',
  'geberit:asset.category',
  'geberit:tag.type',
  'geberit:step.product-ref',
  'geberit:step.product-item-ref',
  'geberit:with-person',
  'geberit:tag.campaign',
  '',
]);

export interface PropertiesProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Properties({
  data = undefined, className = '',
}: PropertiesProps): React.ReactNode {
  return (
    <div className={clsx(className)}>
      <div className={styles.row}>
        <div>ID</div>
        <div>{data?.assetId}</div>
      </div>

      <List excludeList={excludeList} data={data?.allData?.metaData?.feature} />

      <div className={styles.row}>
        <div>Upload MIME Type</div>
        <div>{data?.format || '-'}</div>
      </div>

      <div className={styles.row}>
        <div>Upload File Size</div>
        <div>{humanFileSize(getFileSize(data))}</div>
      </div>
    </div>
  );
}
