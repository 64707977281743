import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../../Common/Content/Wrapper';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { useAssetByIdGet } from '../../../hooks/api/asset';
import Gallery from './Gallery';
import Info from './Info';
import CollapseInfo from './CollapseInfo';
import Properties from './CollapseInfo/Properties';
import Description from './CollapseInfo/Description';
import Rights from './CollapseInfo/Rights';
import NotFound from '../NotFound';
import Keywords from './Keywords';

import styles from './index.module.scss';

export default function Asset(): React.ReactNode {
  const { assetId } = useParams();
  const assetByIdGet = useAssetByIdGet();

  useEffect(() => {
    assetByIdGet.fetch(undefined, assetId);
  }, [assetId]);

  useEffect(() => {
    if (assetByIdGet.data && !assetByIdGet.loading && !assetByIdGet.error) {
      document.title = `Asset: ${assetByIdGet.data?.name}`;
    }
  }, [assetByIdGet.data]);

  if (assetByIdGet.error) {
    return (
      <ContentWrapper className={styles.wrapper}>
        <NotFound />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper className={styles.wrapper}>
      <Breadcrumbs
        loading={assetByIdGet.loading}
        list={[{
          name: assetByIdGet.data?.name || '',
        }]}
      />

      <div className={styles.main}>
        <Gallery data={assetByIdGet.data} />
        <Info asset={assetByIdGet.data} />
      </div>

      <div className={styles.collapse}>
        <Keywords asset={assetByIdGet.data} />

        <CollapseInfo type="Properties">
          <Properties data={assetByIdGet.data} />
        </CollapseInfo>
        <CollapseInfo type="Description">
          <Description data={assetByIdGet.data} />
        </CollapseInfo>

        {Object.keys(assetByIdGet.data?.allData?.usageRestrictions || {}).length > 0 ? (
          <CollapseInfo type="Usage rights">
            <Rights data={assetByIdGet.data} />
          </CollapseInfo>
        ) : null}
      </div>
    </ContentWrapper>
  );
}
